<template>
  <div
    id="contents"
    :class="{
      quality_report_total: tabIndex == 0,
      quality_report_material: tabIndex == 1,
      quality_report_process: tabIndex == 2,
      quality_report_product: tabIndex == 3,
    }"
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToQualityReportPage'"
          :options="[
            { title: '전체', name: 'all_report' },
            { title: '원자재', name: 'material_report' },
            { title: '공정', name: 'fare_report' },
            { title: '제품', name: 'product_report' },
          ]"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li :class="tabIndex == 0 ? 'active' : ''">
            <a @click="SetOpenTabIndex(0)">전체</a>
          </li>
          <li :class="tabIndex == 1 ? 'active' : ''">
            <a @click="SetOpenTabIndex(1)">원자재</a>
          </li>
          <li :class="tabIndex == 2 ? 'active' : ''">
            <a @click="SetOpenTabIndex(2)">공정</a>
          </li>
          <li :class="tabIndex == 3 ? 'active' : ''">
            <a @click="SetOpenTabIndex(3)">제품</a>
          </li>
        </ul>
      </div>
      <report-all-form v-if="tabIndex == 0"></report-all-form>
      <report-material-form v-if="tabIndex == 1"></report-material-form>
      <report-process-form v-if="tabIndex == 2"></report-process-form>
      <report-product-form v-if="tabIndex == 3"></report-product-form>
    </div>
  </div>
</template>

<script>
import ReportAllForm from '@/views/forms/Quality/Report/ReportAllForm.vue';
import ReportMaterialForm from '@/views/forms/Quality/Report/ReportMaterialForm.vue';
import ReportProcessForm from '@/views/forms/Quality/Report/ReportProcessForm.vue';
import ReportProductForm from '@/views/forms/Quality/Report/ReportProductForm.vue';
import { mapGetters, mapMutations } from 'vuex';
import FavoriteMixin from '@/mixins/favorite';
import ClosePageMixin from '@/mixins/closePage';
import AsideSelectric from '@/layouts/components/AsideSelectric';

export default {
  mixins: [FavoriteMixin, ClosePageMixin],
  components: {
    ReportAllForm,
    ReportMaterialForm,
    ReportProcessForm,
    ReportProductForm,
    AsideSelectric,
  },
  computed: {
    ...mapGetters({
      tabIndex: 'getOpenTabIndexFromQualityReportPage',
    }),
  },
  methods: {
    ...mapMutations({
      SetOpenTabIndex: 'setOpenTabIndexToQualityReportPage',
    }),
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitQualityReportPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style lang="scss" scope ReportAllFormd></style>
