import { mapGetters, mapMutations } from 'vuex';
export default {
  computed: {
    ...mapGetters({
      opened_menus: 'getOpenedMenus',
    }),
  },
  methods: {
    ...mapMutations(['removeOpenedMenu']),
    CloseThisPage() {
      try {
        const routedPath = this.$router.currentRoute.path;
        const topPage = this.opened_menus.find(x => x.path == routedPath);
        const topIndex = this.opened_menus.findIndex(x => x.path == routedPath);
        if (topPage.selected == true) {
          if (this.opened_menus.length == 1) {
            this.$router.push('/main');
          } else if (topIndex == this.opened_menus.length - 1) {
            this.$router.push(this.opened_menus[topIndex - 1].path);
          } else {
            this.$router.push(this.opened_menus[topIndex + 1].path);
          }
        }
        this.removeOpenedMenu(topIndex);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
