import SpinnerMixin from '@/mixins/spinner';
import ModalMixin from '@/mixins/modal';
export default {
  mixins: [SpinnerMixin, ModalMixin],
  methods: {
    async FETCH(actionName, what_u_load) {
      this.showSpinner();
      await this.$store
        .dispatch(actionName)
        .then(() => {})
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로드 중 오류',
            `${what_u_load}정보를 불러오는 중 오류가 발생하였습니다.`,
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    async FETCH_NO_SPINNER(actionName, what_u_load) {
      await this.$store
        .dispatch(actionName)
        .then(response => {
          return response;
        })
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로드 중 오류',
            `${what_u_load}정보를 불러오는 중 오류가 발생하였습니다.`,
          );
        })
        .finally(() => {});
    },
    async FETCH_NO_SPINNER_NO_POP_UP(actionName, what_u_load) {
      await this.$store
        .dispatch(actionName)
        .then(response => {
          return response;
        })
        .catch(error => {
          console.log(what_u_load + ':' + error);
        })
        .finally(() => {});
    },
    async FETCH_WITH_PAYLOAD(actionName, payload, what_u_load) {
      this.showSpinner();
      console.log('PAYLOAD FROM FETCH_WITH_PAYLOAD', payload);
      await this.$store
        .dispatch(actionName, payload)
        .then(() => {})
        .catch(error => {
          console.log('ERROR FROM FETCH_WITH_PAYLOAD', error);
          this.openOneButtonModal(
            '로드 중 오류',
            `${what_u_load}정보를 불러오는 중 오류가 발생하였습니다.`,
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    async FETCH_WITH_PAYLOAD_NO_SPINNER(actionName, payload, what_u_load) {
      console.log('PAYLOAD FROM FETCH_WITH_PAYLOAD', payload);
      await this.$store
        .dispatch(actionName, payload)
        .then(() => {})
        .catch(error => {
          console.log('ERROR FROM FETCH_WITH_PAYLOAD', error);
          this.openOneButtonModal(
            '로드 중 오류',
            `${what_u_load}정보를 불러오는 중 오류가 발생하였습니다.`,
          );
        });
    },
    async INSERT_WITH_PAYLOAD(actionName, payload, what_u_load) {
      return new Promise((resolve, reject) => {
        this.showSpinner();
        console.log('PAYLOAD FROM INSERT_WITH_PAYLOAD', payload);
        this.$store
          .dispatch(actionName, payload)
          .then(response => {
            resolve(response);
            this.hideSpinner();
          })
          .catch(error => {
            console.log('ERROR FROM INSERT_WITH_PAYLOAD', error);
            this.openOneButtonModal(
              '등록 중 오류',
              `${what_u_load}정보를 등록하는 중 오류가 발생하였습니다.`,
            );
            this.hideSpinner();
            reject(error);
          });
      });
    },
    async UPDATE_WITH_PAYLOAD(actionName, payload, what_u_load) {
      return new Promise((resolve, reject) => {
        this.showSpinner();
        console.log('PAYLOAD FROM UPDATE_WITH_PAYLOAD', payload);
        this.$store
          .dispatch(actionName, payload)
          .then(response => {
            this.hideSpinner();
            resolve(response);
          })
          .catch(error => {
            console.log('ERROR FROM UPDATE_WITH_PAYLOAD', error);
            this.openOneButtonModal(
              '수정 중 오류',
              `${what_u_load}정보를 수정하는 중 오류가 발생하였습니다.`,
            );
            this.hideSpinner();
            reject(error);
          });
      });
    },
    async DELETE_WITH_PAYLOAD(actionName, payload, what_u_load) {
      return new Promise((resolve, reject) => {
        this.showSpinner();
        console.log('PAYLOAD FROM DELETE_WITH_PAYLOAD', payload);
        this.$store
          .dispatch(actionName, payload)
          .then(response => {
            this.hideSpinner();
            resolve(response);
          })
          .catch(error => {
            console.log('ERROR FROM DELETE_WITH_PAYLOAD', error);
            this.openOneButtonModal(
              '삭제 중 오류',
              `${what_u_load}정보를 삭제하는 중 오류가 발생하였습니다.`,
            );
            this.hideSpinner();
            reject(error);
          });
      });
    },
    findInfoFromId(where_u_find, search_id) {
      const hit = where_u_find.find(x => x.id == search_id);
      return hit != undefined ? hit : '';
    },
  },
};
