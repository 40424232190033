<template>
  <div class="article">
    <div class="option_cont tbl_option">
      <div class="opt_top">
        <div class="option_date">
          <span>검사일자 조회</span>
          <div class="input_text">
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              :value="startDate"
              @change="setStartDate($event)"
            />
          </div>
          <p>~</p>
          <div class="input_text">
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              :value="endDate"
              @change="setEndDate($event)"
            />
          </div>
        </div>
        <div class="check_cont" v-if="filterResourceType != null">
          <div class="input_checkbox">
            <label
              class="chk_box"
              :class="{
                active: filterResourceType.includes(
                  resource_types.find(x => x.detail.includes('raw')).id,
                ),
              }"
              @click="
                toggleCheckBox(
                  resource_types.find(x => x.detail.includes('raw')).id,
                )
              "
            >
              <i class="fa fa-check"></i>
            </label>
            <input type="checkbox" id="checkbox1" />
            <label
              for="checkbox1"
              class="label_txt"
              @click="
                toggleCheckBox(
                  resource_types.find(x => x.detail.includes('raw')).id,
                )
              "
              >원자재</label
            >
          </div>
          <div class="input_checkbox">
            <label
              class="chk_box"
              :class="{
                active: filterResourceType.includes(
                  resource_types.find(x => x.detail.includes('sub')).id,
                ),
              }"
              @click="
                toggleCheckBox(
                  resource_types.find(x => x.detail.includes('sub')).id,
                )
              "
            >
              <i class="fa fa-check"></i>
            </label>
            <input type="checkbox" id="checkbox2" />
            <label
              for="checkbox2"
              class="label_txt"
              @click="
                toggleCheckBox(
                  resource_types.find(x => x.detail.includes('sub')).id,
                )
              "
              >부자재</label
            >
          </div>
          <div class="input_checkbox">
            <label
              class="chk_box"
              :class="{
                active: filterResourceType.includes(
                  resource_types.find(x => x.detail.includes('package')).id,
                ),
              }"
              @click="
                toggleCheckBox(
                  resource_types.find(x => x.detail.includes('package')).id,
                )
              "
            >
              <i class="fa fa-check"></i>
            </label>
            <input type="checkbox" id="checkbox3" />
            <label
              for="checkbox3"
              class="label_txt"
              @click="
                toggleCheckBox(
                  resource_types.find(x => x.detail.includes('package')).id,
                )
              "
              >포장재</label
            >
          </div>
        </div>
        <!-- <button class="excel_btn">엑셀 저장</button> -->
      </div>
      <div class="input_radio">
        <div>
          <label
            for="radio1"
            class="label"
            :class="{ active: radio.all }"
            @click="radioSelect('all')"
          ></label>
          <input type="radio" id="radio1" name="search_opt" />
          <label for="radio1" class="label_txt">전체 원자재 조회</label>
        </div>
        <div>
          <label
            for="radio2"
            class="label"
            :class="{ active: radio.include }"
            @click="radioSelect('include')"
          ></label>
          <input type="radio" id="radio2" name="search_opt" />
          <label for="radio2" class="label_txt">원자재 포함 조회</label>
        </div>
        <div>
          <label
            for="radio3"
            class="label"
            :class="{ active: radio.notInclude }"
            @click="radioSelect('notInclude')"
          ></label>
          <input type="radio" id="radio3" name="search_opt" />
          <label for="radio3" class="label_txt">원자재 제외 조회</label>
        </div>
      </div>
      <div class="ledger_opt" v-show="!this.radio.all">
        <button
          type="button"
          class="btn_ledger"
          @click="showResourceSearch = true"
        >
          {{ this.radio.include ? '포함' : '제외' }} 항목 선택
        </button>
        <transition-group
          name="selected"
          mode="in-out"
          tag="ul"
          type="transition"
          class="selected_items_wrap"
          v-if="filterMaterial.length > 0"
        >
          <li
            class="selected_item"
            v-for="(item, index) in filterMaterial"
            :key="item.id"
          >
            <span>{{ findInfoFromId(materials, item.id).name }}</span>
            <button
              type="button"
              class="delete_item"
              @click="filterMaterial.splice(index, 1)"
            ></button>
          </li>
        </transition-group>
      </div>
    </div>
    <div class="btn_wrap">
      <div class="input_checkbox">
        <label
          class="chk_box"
          :class="{ active: checkOnlyFail }"
          @click="
            $store.commit('setCheckOnlyFailToReportMaterial', !checkOnlyFail)
          "
        >
          <i class="fa fa-check"></i>
        </label>
        <input
          type="checkbox"
          id="checkbox100"
          @click="
            $store.commit('setCheckOnlyFailToReportMaterial', !checkOnlyFail)
          "
        />
        <label for="checkbox100" class="label_txt">불량내역만 보기</label>
      </div>
      <button class="btn_search" @click="FETCH_MATERIAL_REPORT()">검색</button>
    </div>
    <div class="chart_cont">
      <div class="chart_info">
        <div>
          <span class="cnt">불량건수</span>
          <span class="material">입고수량</span>
        </div>
      </div>
      <div class="chart_data">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in chartData"
              :key="item.id"
            >
              <div class="quality_info">
                <h3>
                  {{
                    findInfoFromId(
                      materials,
                      groupMaterialDateForChart[index].material_id,
                    ).name
                  }}({{
                    findInfoFromId(
                      units,
                      findInfoFromId(
                        materials,
                        groupMaterialDateForChart[index].material_id,
                      ).incoming_unit_id,
                    ).name
                  }})
                </h3>
                <span
                  >검사수량 :
                  {{
                    groupMaterialDateForChart[index].in_quantity | makeComma
                  }}</span
                >
                <span
                  >합격수량 :
                  {{
                    groupMaterialDateForChart[index].pass_quantity | makeComma
                  }}</span
                >
                <br />
                <span
                  >불량수량 :
                  {{
                    groupMaterialDateForChart[index].unpass_quantity | makeComma
                  }}</span
                >
                <span
                  >불량률 :
                  {{
                    groupMaterialDateForChart[index].unpass_percent | makeComma
                  }}
                  %</span
                >
              </div>

              <report-bar-chart
                :data="item.data"
                :options="item.options"
                class="report_bar_chart"
              ></report-bar-chart>
            </div>
          </div>
        </div>
      </div>
      <div
        class="mes_tbl_wrap"
        v-if="
          filteredMateiralReports != undefined &&
            filteredMateiralReports.length > 0
        "
      >
        <table class="mes_tbl">
          <thead>
            <tr>
              <th rowspan="2">입고일자</th>
              <th colspan="3">원자재</th>
              <th colspan="2">불량유형</th>
              <th rowspan="2">불량률</th>
            </tr>
            <tr>
              <th>원자재명</th>
              <th>구매처</th>
              <th>입고수</th>
              <th>불량유형</th>
              <th>불량수</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in filteredMateiralReports" :key="item.id">
              <td>{{ item.input_date }}</td>
              <td>{{ findInfoFromId(materials, item.material_id).name }}</td>
              <td :class="{ danger: item.unpass_percent >= 10 }">
                {{ findInfoFromId(companys, item.company_id).name }}
              </td>
              <td>{{ item.in_quantity | makeComma }}</td>
              <td>{{ item.verification_name }}</td>
              <td>{{ item.unpass_quantity | makeComma }}</td>
              <td :class="{ danger: item.unpass_percent >= 10 }">
                {{ item.unpass_percent }}%
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <resource-search
      v-if="showResourceSearch"
      :filter_type="1"
      @onclose="showResourceSearch = false"
      :ignore_list="filterMaterial"
      @onselect="
        $event => {
          pushFilterMaterial($event);
          showResourceSearch = false;
        }
      "
    ></resource-search>
  </div>
</template>

<script>
import ReportBarChart from '@/layouts/components/charts/ReportBarChart';
import Swiper from 'swiper';
import fetchMixin from '@/mixins/fetch';
import { yyyymmdd } from '@/utils/func';
import ResourceSearch from '@/layouts/components/search-popup/ResourceSearch';
import 'swiper/swiper-bundle.css';
import { mapGetters } from 'vuex';
export default {
  mixins: [fetchMixin],
  components: {
    ReportBarChart,
    ResourceSearch,
  },
  data() {
    return {
      swiper: null,
      showResourceSearch: false,
      rend: 0,
      fontSize: this.$screen.width,
    };
  },
  computed: {
    ...mapGetters({
      materialReports: 'getMaterialReportFromReportMaterial',
      startDate: 'getStartDateFromReportMaterial',
      endDate: 'getEndDateFromReportMaterial',
      date: 'getDateFromReportMaterial',
      filterResourceType: 'getFilterResourceTypeArrayFromReportMaterial',
      filterMaterial: 'getFilterMaterialArrayFromReportMaterial',
      radio: 'getRadioFromReportMaterial',
      materials: 'getMaterial',
      companys: 'getCompany',
      units: 'getUnitCodes',
      resource_types: 'getResourceType',
      searchFilterResourceType: 'getSearchFilterResourceTypeFromReportMaterial',
      searchFilterMaterial: 'getSearchFilterMaterialFromReportMaterial',
      searchRadio: 'getSearchRadioFromReportMaterial',
      searchStartDate: 'getSearchStartDateFromReportMaterial',
      searchEndDate: 'getSearchEndDateFromReportMaterial',
      verifications: 'getBaseVerificationFromQualityManagement',
      checkOnlyFail: 'getCheckOnlyFailFromReportMaterial',
    }),
    groupMaterialDateForChart() {
      let reports = this.lodash.clonedeep(this.materialReports).reverse();
      reports = reports.filter(x =>
        this.searchFilterResourceType.includes(
          this.findInfoFromId(this.materials, x.material_id).resource_type_id,
        ),
      );

      const searchFilterMaterialMap = this.lodash
        .clonedeep(this.searchFilterMaterial)
        .map(x => x.id);
      if (this.searchRadio.include) {
        reports = reports.filter(x =>
          searchFilterMaterialMap.includes(x.material_id),
        );
      } else if (this.searchRadio.notInclude) {
        reports = reports.filter(
          x => !searchFilterMaterialMap.includes(x.material_id),
        );
      }
      const obj = reports.reduce(function(rv, x) {
        (rv[x['material_id']] = rv[x['material_id']] || []).push(x);
        return rv;
      }, {});
      let arr = [];
      for (let k in obj) {
        const in_quantity = obj[k]
          .map(x => x.in_quantity)
          .reduce((a, b) => this.$decimalAdd(a, b), 0);
        const pass_quantity = obj[k]
          .map(x => x.pass_quantity)
          .reduce((a, b) => this.$decimalAdd(a, b), 0);
        const unpass_quantity = obj[k]
          .map(x => x.unpass_quantity)
          .reduce((a, b) => this.$decimalAdd(a, b), 0);
        const group_date = obj[k].reduce(function(rv, x) {
          (rv[x['input_date']] = rv[x['input_date']] || []).push(x);
          return rv;
        }, {});
        let arr2 = [];
        for (let i in group_date) {
          const in_quantity = group_date[i]
            .map(x => x.in_quantity)
            .reduce((a, b) => this.$decimalAdd(a, b), 0);
          const pass_quantity = group_date[i]
            .map(x => x.pass_quantity)
            .reduce((a, b) => this.$decimalAdd(a, b), 0);
          const unpass_quantity = group_date[i]
            .map(x => x.unpass_quantity)
            .reduce((a, b) => this.$decimalAdd(a, b), 0);
          arr2.push({
            input_date: i,
            in_quantity: in_quantity,
            pass_quantity: pass_quantity,
            unpass_quantity: unpass_quantity,
            unpass_percent: this.$makeNumberFixed2(
              this.$decimalMul(
                this.$decimalDiv(unpass_quantity, in_quantity),
                100,
              ),
            ),
            pass_percent: this.$makeNumberFixed2(
              this.$decimalMul(
                this.$decimalDiv(unpass_quantity, in_quantity),
                100,
              ),
            ),
          });
        }
        arr.push({
          material_id: k,
          in_quantity: in_quantity,
          pass_quantity: pass_quantity,
          unpass_quantity: unpass_quantity,
          unpass_percent: this.$makeNumberFixed2(
            this.$decimalMul(
              this.$decimalDiv(unpass_quantity, in_quantity),
              100,
            ),
          ),
          pass_percent: this.$makeNumberFixed2(
            this.$decimalMul(
              this.$decimalDiv(unpass_quantity, in_quantity),
              100,
            ),
          ),
          date_list: arr2,
        });
      }
      return arr;
    },
    chartData() {
      try {
        if (this.groupMaterialDateForChart == undefined) {
          return [];
        }
        let chartArray = [];
        let cloneGroup = this.lodash.clonedeep(this.groupMaterialDateForChart);

        cloneGroup.forEach(el => {
          let data = {
            labels: el.date_list.map(x => x.input_date.substr(2, 9)),
            datasets: [
              {
                label: '입고수량',
                borderColor: '#004a89',
                data: el.date_list.map(x => x.in_quantity),
                type: 'line',
                fill: false,
                pointRadius: 4,
                pointBackgroundColor: '#004a89',
                categoryPercentage: 0.4,
                barPercentage: 0.9,
                lineTension: 0,
                tooltip: false,
              },
              {
                label: '불량건수',
                categoryPercentage: 0.4,
                barPercentage: 0.9,
                backgroundColor: '#ffabab',
                data: el.date_list.map(x => x.unpass_quantity),
                tooltip: false,
              },
            ],
          };

          let fontSize = 12;
          if (this.screenWidth < 1280) {
            fontSize = 9;
          }
          let options = {
            legend: {
              responsive: true,
              position: 'top',
              align: 'middle',
              display: true,
              labels: {
                boxWidth: 12,
              },
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    fontSize,
                  },
                },
              ],
              yAxes: [
                {
                  stacked: false,
                  ticks: {
                    beginAtZero: true,
                    fontSize,
                    callback: value => {
                      return this.$makeComma(value);
                    },
                  },
                },
              ],
            },
            plugins: {
              datalabels: {
                color: '#ffffff',
              },
            },
            elements: {
              point: {
                pointStyle: 'circle',
                pointColor: '#004a89',
                pointBackgroundColor: '#004a89',
              },
            },
            showTooltips: true,
            tooltips: {
              mode: 'nearest',
              intersect: false,
              custom: function(tooltip) {
                if (!tooltip) return;
                tooltip.displayColors = false;
              },
              callbacks: {
                label: tooltipItems => {
                  if (tooltipItems.datasetIndex != -1) {
                    var multistringText = [];
                    multistringText.push(
                      `[입고수량] : ${this.$makeComma(
                        this.$makeNumberFixed2(
                          el.date_list[tooltipItems.index].in_quantity,
                        ),
                      )}`,
                    );
                    multistringText.push(
                      `[불량건수] : ${this.$makeComma(
                        this.$makeNumberFixed2(
                          el.date_list[tooltipItems.index].unpass_quantity,
                        ),
                      )}`,
                    );
                    console.log(tooltipItems);
                    console.log(data);
                    return multistringText;
                  }
                },
              },
            },
          };
          chartArray.push({
            id: chartArray.length + this.rend,
            data,
            options,
          });
        });
        return chartArray;
      } catch (error) {
        return error;
      }
    },
    filteredMateiralReports() {
      if (this.materialReports != undefined) {
        let reports = this.lodash.clonedeep(this.materialReports);
        reports = reports.filter(x =>
          this.searchFilterResourceType.includes(
            this.findInfoFromId(this.materials, x.material_id).resource_type_id,
          ),
        );

        const searchFilterMaterialMap = this.lodash
          .clonedeep(this.filterMaterial)
          .map(x => x.id);

        if (this.searchRadio.include) {
          reports = reports.filter(x =>
            searchFilterMaterialMap.includes(x.material_id),
          );
        } else if (this.searchRadio.notInclude) {
          reports = reports.filter(
            x => !searchFilterMaterialMap.includes(x.material_id),
          );
        }
        if (this.checkOnlyFail) {
          reports = reports.filter(x => x.unpass_quantity > 0);
        }

        reports.forEach(el => {
          el.unpass_percent = this.$makeNumberFixed2(el.unpass_percent);
          let veriLabel = '';
          if (
            el.verification_id != undefined &&
            el.verification_id.includes(',')
          ) {
            const veriArray = el.verification_id.split(',');
            veriArray.forEach((el2, index) => {
              if (index == veriArray.length - 1) {
                veriLabel += this.findInfoFromId(
                  this.verifications,
                  Number(el2),
                ).inspection_item;
              } else {
                veriLabel +=
                  this.findInfoFromId(this.verifications, Number(el2))
                    .inspection_item + ', ';
              }
            });
            el.verification_name = veriLabel;
          } else {
            el.verification_name = this.findInfoFromId(
              this.verifications,
              Number(el.verification_id),
            ).inspection_item;
          }
        });
        return reports;
      } else {
        return [];
      }
    },
  },
  methods: {
    setStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.endDate) {
          this.openOneButtonModal(
            '검사일자 조회기간 오류',
            `검사일자 조회기간 최후날짜(${this.endDate})<br>이후의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.startDate;
        } else {
          this.$store.commit('setStartDateToReportMaterial', e.target.value);
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit(
          'setStartDateToReportMaterial',
          yyyymmdd(new Date()),
        );
      }
    },
    setEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.startDate) {
          this.openOneButtonModal(
            '검사일자 조회기간 오류',
            `검사일자 조회기간 최초날짜(${this.startDate})<br>이전의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.endDate;
        } else {
          this.$store.commit('setEndDateToReportMaterial', e.target.value);
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setEndDateToReportMaterial', yyyymmdd(new Date()));
      }
    },

    async FETCH_MATERIAL_REPORT() {
      this.$store.commit(
        'setSearchFilterResourceTypeToReportMaterial',
        this.lodash.clonedeep(this.filterResourceType),
      );
      this.$store.commit(
        'setSearchFilterMaterialToReportMaterial',
        this.lodash.clonedeep(this.filterMaterial),
      );
      this.$store.commit(
        'setSearchRadioToReportMaterial',
        this.lodash.clonedeep(this.radio),
      );
      this.$store.commit(
        'setSearchStartDateToReportMaterial',
        this.lodash.clonedeep(this.date.Date),
      );
      this.$store.commit(
        'setSearchEndDateToReportMaterial',
        this.lodash.clonedeep(this.endDate),
      );
      await this.FETCH_WITH_PAYLOAD(
        'FETCH_MATERIAL_QUALITY_REPORT_TO_REPORT_MATERIAL',
        {
          start: this.startDate,
          end: this.endDate,
        },
        '자재 품질보고서',
      );
      this.rend += 100;
    },
    pushFilterMaterial(arg) {
      this.filterMaterial.push(arg);
    },
    toggleCheckBox(id) {
      const index = this.filterResourceType.findIndex(x => x == id);
      if (index == -1) {
        this.filterResourceType.push(id);
      } else {
        this.filterResourceType.splice(index, 1);
      }
    },
    radioSelect(propertyName) {
      const keyNames = Object.keys(this.radio);
      keyNames.forEach(x => {
        this.radio[x] = false;
      });
      this.radio[propertyName] = true;
    },
  },
  mounted() {
    this.swiper = new Swiper('.swiper-container', {
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: 0,
      freeMode: true,
      resizeReInit: true,
      observer: true,
      observeParents: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.button-next',
        prevEl: '.button-prev',
      },
    });
  },
  async created() {
    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '자재');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.resource_types.length < 1) {
      await this.FETCH('FETCH_RESOURCE_TYPE', '자원유형');
    }
    if (this.verifications.length < 1) {
      await this.FETCH('FETCH_BASE_VERIFICATION', '검증');
    }
    if (this.startDate == null) {
      var date = new Date();
      this.$store.commit('setEndDateToReportMaterial', yyyymmdd(date));
      var date2 = new Date(date.setDate(date.getDate() - 14));
      this.$store.commit('setStartDateToReportMaterial', yyyymmdd(date2));
    }
    if (this.filterResourceType == null) {
      this.$store.commit(
        'setFilterResourceTypeArrayToReportMaterial',
        this.lodash.clonedeep(
          this.resource_types
            .filter(x => x.detail.includes('material'))
            .map(x => x.id),
        ),
      );
    }
  },
};
</script>

<style lang="scss" scoped></style>
