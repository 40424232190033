var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    quality_report_total: _vm.tabIndex == 0,
    quality_report_material: _vm.tabIndex == 1,
    quality_report_process: _vm.tabIndex == 2,
    quality_report_product: _vm.tabIndex == 3,
  },attrs:{"id":"contents"}},[_c('div',{staticClass:"contents_head"},[_c('h2',[_vm._v(_vm._s(_vm.$getPageTitle(_vm.$route.path)))]),(_vm.$screen.width < 1280)?_c('div',{staticClass:"aside"},[_c('aside-selectric',{attrs:{"id":'tabletAsideSelectric',"watch":_vm.tabIndex,"commit":'setOpenTabIndexToQualityReportPage',"options":[
          { title: '전체', name: 'all_report' },
          { title: '원자재', name: 'material_report' },
          { title: '공정', name: 'fare_report' },
          { title: '제품', name: 'product_report' } ]}})],1):_vm._e(),_c('div',{staticClass:"management_btn_group"},[_c('div',[_c('span',{staticClass:"add_favorite",class:{ on: _vm.isFavorOn },on:{"click":_vm.AddFavorite}},[_vm._v("즐겨찾기 추가")]),_c('button',{staticClass:"btn_admin",on:{"click":function($event){return _vm.CloseThisPage()}}},[_vm._v("닫기")])])])]),_c('div',{staticClass:"section"},[(_vm.$screen.width >= 1280)?_c('div',{staticClass:"aside"},[_c('ul',[_c('li',{class:_vm.tabIndex == 0 ? 'active' : ''},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(0)}}},[_vm._v("전체")])]),_c('li',{class:_vm.tabIndex == 1 ? 'active' : ''},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(1)}}},[_vm._v("원자재")])]),_c('li',{class:_vm.tabIndex == 2 ? 'active' : ''},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(2)}}},[_vm._v("공정")])]),_c('li',{class:_vm.tabIndex == 3 ? 'active' : ''},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(3)}}},[_vm._v("제품")])])])]):_vm._e(),(_vm.tabIndex == 0)?_c('report-all-form'):_vm._e(),(_vm.tabIndex == 1)?_c('report-material-form'):_vm._e(),(_vm.tabIndex == 2)?_c('report-process-form'):_vm._e(),(_vm.tabIndex == 3)?_c('report-product-form'):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }