<template>
  <div class="article">
    <div class="option_cont tbl_option">
      <div class="option_date">
        <span>검사일자 조회</span>
        <div class="input_text">
          <input
            type="date"
            placeholder="YYYY-MM-DD"
            :value="startDate"
            @change="setStartDate($event)"
          />
        </div>
        <p>~</p>
        <div class="input_text">
          <input
            type="date"
            placeholder="YYYY-MM-DD"
            :value="endDate"
            @change="setEndDate($event)"
          />
        </div>
      </div>
      <div class="input_radio">
        <div>
          <label
            for="radio1"
            class="label"
            :class="{ active: radioState.radio_material }"
            @click="
              () => {
                $store.commit('setRadioStateToReportAll', 'material');
              }
            "
          ></label>
          <input type="radio" id="radio1" name="search_opt" />
          <label for="radio1" class="label_txt">원자재</label>
        </div>
        <div>
          <label
            for="radio2"
            class="label"
            :class="{ active: radioState.radio_process }"
            @click="
              () => {
                $store.commit('setRadioStateToReportAll', 'process');
              }
            "
          ></label>
          <input type="radio" id="radio2" name="search_opt" />
          <label for="radio2" class="label_txt">공정</label>
        </div>
        <div>
          <label
            for="radio3"
            class="label"
            :class="{ active: radioState.radio_product }"
            @click="
              () => {
                $store.commit('setRadioStateToReportAll', 'product');
              }
            "
          ></label>
          <input type="radio" id="radio3" name="search_opt" />
          <label for="radio3" class="label_txt">완제품</label>
        </div>
      </div>
      <button class="btn_search2" @click="searchData()">검색</button>
      <!-- <button class="excel_btn">엑셀 저장</button> -->
    </div>
    <div class="chart_cont">
      <div class="bar_chart">
        <div class="chart_info">
          <h6>기간 중 평균 불량률</h6>
          <div>
            <span class="cnt">합격률(%)</span>
            <span class="material">불량률(%)</span>
          </div>
        </div>
        <div class="chart_data">
          <pie-chart
            :data="chartDataGroupByAll.data"
            :options="chartDataGroupByAll.options"
            :key="key"
          ></pie-chart>
        </div>
      </div>
      <div class="bar_chart">
        <div class="chart_info">
          <h6>
            기간 중
            {{
              radioSearch.radio_material
                ? '원자재'
                : radioSearch.radio_process
                ? '공정'
                : '제품'
            }}별 불량률
          </h6>
          <div>
            <span class="cnt">합격률(%)</span>
            <span class="material">불량률(%)</span>
          </div>
        </div>
        <div class="chart_data">
          <report-bar-chart
            :data="chartDataGroupByElement.data"
            :options="chartDataGroupByElement.options"
            :key="key"
          ></report-bar-chart>
        </div>
      </div>
      <div class="line_chart">
        <div class="chart_info">
          <h6>기간별 평균 불량률</h6>
          <div>
            <span class="material">합격률(%)</span>
            <span class="cnt">불량률(%)</span>
          </div>
        </div>
        <div class="chart_data">
          <line-chart
            :data="chartDataGroupByInputDate.data"
            :options="chartDataGroupByInputDate.options"
            :key="key"
          ></line-chart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fetchMixin from '@/mixins/fetch';
import { mapGetters } from 'vuex';
import { yyyymmdd } from '@/utils/func';
import ReportBarChart from '@/layouts/components/charts/ReportBarChart';
import LineChart from '@/layouts/components/charts/LineChart';
import PieChart from '@/layouts/components/charts/PieChart';
import { makeComma } from '@/utils/filters';

export default {
  mixins: [fetchMixin],
  components: {
    ReportBarChart,
    LineChart,
    PieChart,
  },
  data() {
    return {
      key: 0,
    };
  },
  computed: {
    ...mapGetters({
      materialReportData: 'getMaterialReportDataFromReportAll',
      materialReportGroupByMaterial:
        'getMaterialReportDataGroupByMaterialFromReportAll',
      materialReportGroupByInputDate:
        'getMaterialReportDataGroupByInputDateFromReportAll',
      seqReportData: 'getSeqReportDataFromReportAll',
      seqReportGroupByProcess: 'getSeqReportDataGroupByProcessFromReportAll',
      seqReportGroupByInputDate:
        'getSeqReportDataGroupByInputDateFromReportAll',
      productReportData: 'getProductReportDataFromReportAll',
      productReportGroupByProduct:
        'getProductReportDataGroupByProductFromReportAll',
      productReportGroupByInputDate:
        'getProductReportDataGroupByInputDateFromReportAll',
      startDate: 'getStartDateFromReportAll',
      endDate: 'getEndDateFromReportAll',
      date: 'getDateFromReportAll',
      radioState: 'getRadioStateFromReportAll',
      radioSearch: 'getRadioSearchFromReportAll',
      materials: 'getMaterial',
      products: 'getProduct',
      process: 'getProcess',
      units: 'getUnitCodes',
    }),
    chartDataGroupByElement() {
      let groupByElement = null;
      let elements = null;
      if (this.radioSearch.radio_material) {
        groupByElement = this.lodash.clonedeep(
          this.materialReportGroupByMaterial,
        );
        elements = this.lodash.clonedeep(this.materials);
        groupByElement.forEach(el => {
          el.element_id = el.material_id;
        });
      } else if (this.radioSearch.radio_process) {
        groupByElement = this.lodash.clonedeep(this.seqReportGroupByProcess);
        elements = this.lodash.clonedeep(this.process);
        groupByElement.forEach(el => {
          el.element_id = el.process_id;
        });
      } else {
        groupByElement = this.lodash.clonedeep(
          this.productReportGroupByProduct,
        );
        elements = this.lodash.clonedeep(this.products);
        groupByElement.forEach(el => {
          el.element_id = el.product_id;
        });
      }

      const id_list = this.lodash.clonedeep(
        groupByElement.map(x => x.element_id),
      );
      let labels = [];
      id_list.forEach(element => {
        labels.push(this.findInfoFromId(elements, element).name);
      });

      const failPercent = groupByElement.map(x =>
        this.$makeNumberFixed2(x.unpass_percent),
      );
      const passPercent = groupByElement.map(x =>
        this.$makeNumberFixed2(x.pass_percent),
      );

      let data = {
        labels: labels,
        datasets: [
          {
            borderColor: '#004a89',
            data: passPercent,
            fill: false,
            pointRadius: 4,
            backgroundColor: '#004a89',
            categoryPercentage: 0.4,
            lineTension: 0,
            tooltip: false,
          },
          {
            categoryPercentage: 0.4,
            borderColor: '#004a89',
            data: failPercent,
            fill: false,
            pointRadius: 4,
            backgroundColor: '#ffabab',
            lineTension: 0,
          },
        ],
      };

      let fontSize = 12;
      if (this.screenWidth < 1280) {
        fontSize = 10;
      }

      let options = {
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              id: 'bar-x-axis1',
              ticks: {
                fontSize,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                max: 100,
                fontSize,
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            color: '#ffffff',
          },
        },
        elements: {
          point: {
            pointStyle: 'circle',
            pointColor: '#004a89',
            pointBackgroundColor: '#004a89',
          },
        },
        showTooltips: true,
        tooltips: {
          mode: 'nearest',
          intersect: false,
          custom: function(tooltip) {
            if (!tooltip) return;
            tooltip.displayColors = false;
          },
          callbacks: {
            label: tooltipItems => {
              if (tooltipItems.datasetIndex != -1) {
                let unit_id = null;
                let unit_name = null;
                if (this.radioSearch.radio_material) {
                  unit_id = this.findInfoFromId(
                    this.materials,
                    groupByElement[tooltipItems.index].material_id,
                  ).incoming_unit_id;
                  unit_name = this.findInfoFromId(this.units, unit_id).name;
                } else if (this.radioSearch.radio_product) {
                  unit_id = this.findInfoFromId(
                    this.products,
                    groupByElement[tooltipItems.index].product_id,
                  ).stock_unit_id;
                  unit_name = this.findInfoFromId(this.units, unit_id).name;
                } else {
                  unit_name = '';
                }

                var multistringText = [];
                multistringText.push(
                  `[합격률] : ${this.$makeNumberFixed2(
                    groupByElement[tooltipItems.index].pass_percent,
                  )} %`,
                );
                multistringText.push(
                  `[불량률] : ${this.$makeNumberFixed2(
                    groupByElement[tooltipItems.index].unpass_percent,
                  )} %`,
                );
                multistringText.push(
                  `[검사수량] : ${makeComma(
                    this.$makeNumberFixed2(
                      groupByElement[tooltipItems.index].in_quantity,
                    ),
                  )} ${unit_name}`,
                );
                multistringText.push(
                  `[불량수량] : ${makeComma(
                    this.$makeNumberFixed2(
                      groupByElement[tooltipItems.index].unpass_quantity,
                    ),
                  )} ${unit_name}`,
                );
                console.log(tooltipItems);
                console.log('data ', data);
                return multistringText;
              }
            },
          },
        },
        hover: {
          mode: 'nearest',
          intersect: true,
        },
      };

      return { data: data, options: options };
    },
    chartDataGroupByInputDate() {
      let failPercent = null;
      let passPercent = null;
      let labels = null;
      if (this.radioSearch.radio_material) {
        failPercent = this.materialReportGroupByInputDate.map(x =>
          this.$makeNumberFixed2(x.unpass_percent),
        );
        passPercent = this.materialReportGroupByInputDate.map(x =>
          this.$makeNumberFixed2(x.pass_percent),
        );
        labels = this.materialReportGroupByInputDate.map(x =>
          x.input_date.substr(2, 9),
        );
      } else if (this.radioSearch.radio_process) {
        failPercent = this.seqReportGroupByInputDate.map(x =>
          this.$makeNumberFixed2(x.unpass_percent),
        );
        passPercent = this.seqReportGroupByInputDate.map(x =>
          this.$makeNumberFixed2(x.pass_percent),
        );
        labels = this.seqReportGroupByInputDate.map(x =>
          x.input_date.substr(2, 9),
        );
      } else {
        failPercent = this.productReportGroupByInputDate.map(x =>
          this.$makeNumberFixed2(x.unpass_percent),
        );
        passPercent = this.productReportGroupByInputDate.map(x =>
          this.$makeNumberFixed2(x.pass_percent),
        );
        labels = this.productReportGroupByInputDate.map(x =>
          x.input_date.substr(2, 9),
        );
      }

      let data = {
        labels: labels.reverse(),
        datasets: [
          {
            borderColor: '#ffabab',
            data: failPercent.reverse(),
            fill: false,
            pointRadius: 4,
            backgroundColor: '#ffabab',
            lineTension: 0,
          },
          {
            borderColor: '#004a89',
            data: passPercent.reverse(),
            fill: false,
            pointRadius: 0,
            backgroundColor: '#004a89',
            lineTension: 0,
          },
        ],
      };

      let fontSize = 12;
      if (this.screenWidth < 1280) {
        fontSize = 10;
      }
      let options = {
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              stacked: false,
              id: 'bar-x-axis1',
              ticks: {
                fontSize,
              },
            },
          ],
          dataset: {
            categoryPercentage: 0.4,
            barPercentage: 0.4,
          },
          yAxes: [
            {
              stacked: false,
              ticks: {
                // beginAtZero: true,
                max: 100,
                fontSize,
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            color: '#ffffff',
          },
        },
        elements: {
          point: {
            pointStyle: 'circle',
            pointColor: '#004a89',
            pointBackgroundColor: '#004a89',
          },
        },
        showTooltips: true,
        tooltips: {
          mode: 'nearest',
          intersect: false,
          custom: function(tooltip) {
            if (!tooltip) return;
            tooltip.displayColors = false;
          },
          callbacks: {
            label: tooltipItems => {
              if (tooltipItems.datasetIndex != -1) {
                var multistringText = [];
                let groupArray = null;
                if (this.radioSearch.radio_material) {
                  groupArray = this.lodash
                    .clonedeep(this.materialReportGroupByInputDate)
                    .reverse();
                  multistringText.push(
                    `[평균 불량률] : ${
                      groupArray[tooltipItems.index].unpass_percent
                    } %`,
                  );
                  groupArray[tooltipItems.index].element.forEach(el => {
                    multistringText.push(
                      `[${
                        this.findInfoFromId(this.materials, el.material_id).name
                      }] 불량률 : ${makeComma(
                        this.$makeNumberFixed2(el.unpass_percent),
                      )} %`,
                    );
                  });
                  return multistringText;
                } else if (this.radioSearch.radio_process) {
                  groupArray = this.lodash
                    .clonedeep(this.seqReportGroupByInputDate)
                    .reverse();
                  multistringText.push(
                    `[평균 불량률] : ${
                      groupArray[tooltipItems.index].unpass_percent
                    } %`,
                  );
                  groupArray[tooltipItems.index].element.forEach(el => {
                    multistringText.push(
                      `[${
                        this.findInfoFromId(this.process, el.process_id).name
                      }] 불량률 : ${makeComma(
                        this.$makeNumberFixed2(el.unpass_percent),
                      )} %`,
                    );
                  });
                  return multistringText;
                } else {
                  groupArray = this.lodash
                    .clonedeep(this.productReportGroupByInputDate)
                    .reverse();
                  multistringText.push(
                    `[평균 불량률] : ${
                      groupArray[tooltipItems.index].unpass_percent
                    } %`,
                  );
                  groupArray[tooltipItems.index].element.forEach(el => {
                    multistringText.push(
                      `[${
                        this.findInfoFromId(this.products, el.product_id).name
                      }] 불량률 : ${makeComma(
                        this.$makeNumberFixed2(el.unpass_percent),
                      )} %`,
                    );
                  });
                  return multistringText;
                }
              }
            },
          },
        },
        // hover: {
        //   mode: 'nearest',
        //   intersect: true,
        // },
      };

      return { data: data, options: options };
    },
    chartDataGroupByAll() {
      let groupTarget = null;
      if (this.radioSearch.radio_material) {
        groupTarget = this.materialReportGroupByInputDate;
      } else if (this.radioSearch.radio_process) {
        groupTarget = this.seqReportGroupByInputDate;
      } else {
        groupTarget = this.productReportGroupByInputDate;
      }
      const failPercent =
        groupTarget
          .map(x => x.unpass_percent)
          .reduce(
            (a, b) => this.$makeNumberFixed2(a) + this.$makeNumberFixed2(b),
            0,
          ) / groupTarget.length;

      const passPercent =
        groupTarget
          .map(x => x.pass_percent)
          .reduce(
            (a, b) => this.$makeNumberFixed2(a) + this.$makeNumberFixed2(b),
            0,
          ) / groupTarget.length;

      let data = {
        labels: ['기간중 평균 합격률', '기간중 평균 불량률'],
        datasets: [
          {
            // borderColor: '#ffabab',
            data: [
              this.$makeNumberFixed2(passPercent),
              this.$makeNumberFixed2(failPercent),
            ],
            fill: false,
            pointRadius: 4,
            backgroundColor: ['#004a89', '#ffabab'],
            lineTension: 0,
          },
        ],
      };

      let tooltipEnable = true;
      if (
        this.materialReportData.length == 0 &&
        this.productReportData.length == 0 &&
        this.seqReportData.length == 0
      )
        tooltipEnable = false;

      let options = {
        legend: {
          display: false,
        },
        hover: {
          mode: 'nearest',
          intersect: true,
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          custom: function(tooltip) {
            if (!tooltip) return;
            tooltip.displayColors = false;
          },
          enabled: tooltipEnable,
          callbacks: {
            label: tooltipItems => {
              if (tooltipItems.datasetIndex === 0) {
                var multistringText = [];
                switch (tooltipItems.index) {
                  case 0:
                    multistringText.push(
                      `[기간중 평균 합격률] : ${this.$makeNumberFixed2(
                        passPercent,
                      )} %`,
                    );
                    break;
                  case 1:
                    multistringText.push(
                      `[기간중 평균 불합격률] : ${this.$makeNumberFixed2(
                        failPercent,
                      )} %`,
                    );
                    break;
                }
                return multistringText;
              }
            },
          },
        },
      };

      return { data: data, options: options };
    },
  },
  methods: {
    setStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.endDate) {
          this.openOneButtonModal(
            '검사일자 조회기간 오류',
            `검사일자 조회기간 최후날짜(${this.endDate})<br>이후의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.startDate;
        } else {
          this.$store.commit('setStartDateToReportAll', e.target.value);
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setStartDateToReportAll', yyyymmdd(new Date()));
      }
    },
    setEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.startDate) {
          this.openOneButtonModal(
            '검사일자 조회기간 오류',
            `검사일자 조회기간 최초날짜(${this.startDate})<br>이전의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.endDate;
        } else {
          this.$store.commit('setEndDateToReportAll', e.target.value);
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setEndDateToReportAll', yyyymmdd(new Date()));
      }
    },
    async searchData() {
      if (this.radioState.radio_material) {
        await this.FETCH_WITH_PAYLOAD(
          'FETCH_MATERIAL_QUALITY_REPORT',
          {
            start: this.startDate,
            end: this.endDate,
          },
          '원자재 품질',
        );
        this.$store.commit('setRadioSearchToReportAll', 'material');
      } else if (this.radioState.radio_process) {
        await this.FETCH_WITH_PAYLOAD(
          'FETCH_SEQ_QUALITY_REPORT',
          {
            start: this.startDate,
            end: this.endDate,
          },
          '공정 품질',
        );
        this.$store.commit('setRadioSearchToReportAll', 'process');
      } else {
        await this.FETCH_WITH_PAYLOAD(
          'FETCH_PRODUCT_QUALITY_REPORT',
          {
            start: this.startDate,
            end: this.endDate,
          },
          '제품 품질',
        );
        this.$store.commit('setRadioSearchToReportAll', 'product');
      }
      this.key += 1;
    },
  },
  async created() {
    if (this.startDate == null) {
      var date = new Date();
      this.$store.commit('setEndDateToReportAll', yyyymmdd(date));
      var date2 = new Date(date.setDate(date.getDate() - 14));
      this.$store.commit('setStartDateToReportAll', yyyymmdd(date2));
    }
    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '자재');
    }
    if (this.process.length < 1) {
      await this.FETCH('FETCH_PROCESS', '공정');
    }
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
  },
};
</script>

<style lang="scss" scoped></style>
