import { mapGetters } from 'vuex';
import fetchMixin from '@/mixins/fetch';
import SpinnerMixin from '@/mixins/spinner';
import ModalMixin from '@/mixins/modal';

export default {
  mixins: [fetchMixin, ModalMixin, SpinnerMixin],
  computed: {
    ...mapGetters({
      userId: 'getUserId',
      users: 'getUsersTempFromUserPage',
      favoriteList: 'getFavoriteFromFavoriteMenuPage',
    }),
    isFavorOn() {
      const routedPath = this.$router.currentRoute.path;
      if (routedPath != '/main') {
        if (this.favoriteList.length > 0) {
          const filterList = this.favoriteList;
          const mapFilterList = filterList.map(x => x.sub_menu);
          const reduceFilterList = mapFilterList.reduce((a, b) => a.concat(b));
          const filterFavor = reduceFilterList.find(
            x => x.sub_detail == routedPath,
          );
          const findId = this.users.find(x => x.account == this.userId).id;
          filterFavor.user_id = findId;
          return filterFavor.state == 1 ? true : false;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    isPermissionOn() {
      const routedPath = this.$router.currentRoute.path;
      if (routedPath != '/main') {
        if (this.favoriteList.length > 0) {
          const filterList = this.favoriteList;
          const mapFilterList = filterList.map(x => x.sub_menu);
          const reduceFilterList = mapFilterList.reduce((a, b) => a.concat(b));
          const filterFavor = reduceFilterList.find(
            x => x.sub_detail == routedPath,
          );
          const findId = this.users.find(x => x.account == this.userId).id;
          filterFavor.user_id = findId;
          return filterFavor.permission == 2 ? true : false;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  methods: {
    checkedPermission(path) {
      if (this.favoriteList.length > 0) {
        const filterList = this.favoriteList;
        const mapFilterList = filterList.map(x => x.sub_menu);
        const reduceFilterList = mapFilterList.reduce((a, b) => a.concat(b));
        const filterFavor = reduceFilterList.find(x => x.sub_detail == path);
        return filterFavor != undefined ? true : false;
      } else {
        return false;
      }
    },
    async FETCH_FAVORITE_MENU() {
      const findId = this.users.find(x => x.account == this.userId);
      await this.$store
        .dispatch('FETCH_FAVORITE_MENU', findId.id)
        .then(() => {})
        .catch(() => {
          this.openOneButtonModal(
            '로드 중 오류',
            '사용자 정보를 불러오는 중 오류가 발생했습니다.',
          );
        })
        .finally(() => {});
    },
    AddFavorite() {
      const routedPath = this.$router.currentRoute.path;
      const filterFavor = this.favoriteList
        .map(x => x.sub_menu)
        .reduce((a, b) => a.concat(b))
        .find(x => x.sub_detail == routedPath);

      const findId = this.users.find(x => x.account == this.userId).id;
      filterFavor.user_id = findId;

      if (filterFavor.state == 0) {
        filterFavor.state = 1;
      } else {
        filterFavor.state = 0;
      }
      this.showSpinner();
      this.$store
        .dispatch('INSERT_FAVORITE_MENU', filterFavor)
        .then(() => {
          this.FETCH_FAVORITE_MENU();
        })
        .catch(error => {
          console.log('FAVORITE MENE ERROR : ', error);
          this.openOneButtonModal(
            '즐겨찾기 메뉴 불러오기 오류',
            '즐겨찾기 메뉴를 불러오는 중 오류발생. 다시 로그인하여주십시오.',
          );
        });

      this.hideSpinner();
    },
  },
  async mounted() {
    if (this.users.length < 1) {
      await this.FETCH('FETCH_USER_LIST', '사용자');
    }
    // if (this.favoriteList.length < 1) {
    //   await this.FETCH_FAVORITE_MENU();
    // }
  },
};
