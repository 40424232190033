<script>
const horizonalLinePlugin = {
  id: 'horizontalLine',
  afterDraw: function(chartInstance) {
    var yValue;
    var yScale = chartInstance.scales['y-axis-0'];
    var canvas = chartInstance.chart;
    var ctx = canvas.ctx;
    var index;
    var line;
    var style;

    chartInstance.data.datasets.forEach((dataset, index) => {
      let meta = chartInstance.getDatasetMeta(index);
      if (!meta.hidden) {
        meta.data.forEach((element, index) => {
          index == 0
            ? (ctx.fillStyle = '#ffabab')
            : (ctx.fillStyle = '#004a89');
          let fontSize = 13;
          let fontStyle = 'normal';
          ctx.font = Chart.helpers.fontString(fontSize, fontStyle);
          let dataString = dataset.data[index].toString();
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          let position = element.tooltipPosition();
          !isNaN(dataString)
            ? ctx.fillText(dataString, position.x, position.y)
            : '';
        });
      }
    });

    if (chartInstance.options.horizontalLine) {
      for (
        index = 0;
        index < chartInstance.options.horizontalLine.length;
        index++
      ) {
        line = chartInstance.options.horizontalLine[index];

        if (!line.style) {
          style = '#080808';
        } else {
          style = line.style;
        }

        if (line.y) {
          yValue = yScale.getPixelForValue(line.y);
        } else {
          yValue = 0;
        }
        ctx.lineWidth = 3;

        if (yValue) {
          window.chart = chartInstance;
          ctx.beginPath();
          ctx.moveTo(0, yValue);
          ctx.lineTo(canvas.width, yValue);
          ctx.strokeStyle = style;
          ctx.stroke();
        }

        if (line.text) {
          ctx.fillStyle = style;
          ctx.fillText(line.text, 0, yValue + ctx.lineWidth);
        }
      }
      return;
    }
  },
};
import Chart from 'chart.js';
import { Pie } from 'vue-chartjs';
export default {
  extends: Pie,
  props: ['data', 'options', 'screenWidth'],
  beforeMount() {
    this.addPlugin(horizonalLinePlugin);
  },
  mounted() {
    this.renderChart(this.data, this.options);
  },
};
</script>
