<script>
export function makeComma(value) {
  if (value == undefined) return '';
  value = String(value).replace(/,/gi, '');
  if (value.trim().length == 0) {
    return '0';
  }

  let returnValue = String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (returnValue.includes('.')) {
    returnValue =
      returnValue.split('.')[0] +
      '.' +
      returnValue.split('.')[1].replace(/,/gi, '');
  }

  return returnValue;
}
const horizonalLinePlugin = {
  id: 'horizontalLine',
  afterDraw: chartInstance => {
    var yValue;
    var yScale = chartInstance.scales['y-axis-0'];
    var canvas = chartInstance.chart;
    var ctx = canvas.ctx;
    var index;
    var line;
    var style;

    chartInstance.data.datasets.forEach((dataset, index) => {
      let meta = chartInstance.getDatasetMeta(index);
      if (!meta.hidden) {
        if (dataset.label != undefined) {
          if (dataset.label.includes('발효탱크')) {
            index == 0
              ? (ctx.fillStyle = '#004a89')
              : index == 1
              ? (ctx.fillStyle = '#6c9bd2')
              : index == 2
              ? (ctx.fillStyle = '#f98787')
              : (ctx.fillStyle = '#a0a0a0');
          } else {
            console.log('adsfs');
            index == 0
              ? (ctx.fillStyle = '#004a89')
              : (ctx.fillStyle = '#ffabab');
          }
        } else {
          index == 0
            ? (ctx.fillStyle = '#004a89')
            : (ctx.fillStyle = '#ffabab');
        }

        dataset.label == '생산' ? (ctx.fillStyle = '#cecece') : '';
        meta.data.forEach((element, index) => {
          // Draw the text in black, with the specified font
          ctx.font = Chart.helpers.fontString(13, 'bold');
          // Just naively convert to string for now
          let dataString = makeComma(dataset.data[index]);
          // Make sure alignment settings are correct
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          let padding = 5;
          let position = element.tooltipPosition();
          if (dataset.label == '누적사용(분)') {
            ctx.fillText(
              dataString,
              position.x - 0.5 * padding,
              position.y - 10 / 2 - padding,
            );
          } else if (dataset.label == '일 평균사용(분)') {
            ctx.fillText(
              dataString,
              position.x + 6 * padding,
              position.y - 1 / 2 - padding,
            );
            ctx.fillStyle = '#ef6262';
          } else {
            ctx.fillText(dataString, position.x, position.y - 13 / 2 - padding);
          }
        });
      }
    });

    if (chartInstance.options.horizontalLine) {
      for (
        index = 0;
        index < chartInstance.options.horizontalLine.length;
        index++
      ) {
        line = chartInstance.options.horizontalLine[index];

        if (!line.style) {
          style = '#080808';
        } else {
          style = line.style;
        }

        if (line.y) {
          yValue = yScale.getPixelForValue(line.y);
        } else {
          yValue = 0;
        }
        ctx.lineWidth = 3;

        if (yValue) {
          window.chart = chartInstance;
          ctx.beginPath();
          ctx.moveTo(0, yValue);
          ctx.lineTo(canvas.width, yValue);
          ctx.strokeStyle = style;
          ctx.stroke();
        }

        if (line.text) {
          ctx.fillStyle = style;
          ctx.fillText(line.text, 0, yValue + ctx.lineWidth);
        }
      }
      return;
    }
  },
};
import { Bar } from 'vue-chartjs';
import Chart from 'chart.js';
export default {
  extends: Bar,
  props: ['data', 'options'],
  beforeMount() {
    this.addPlugin(horizonalLinePlugin);
  },
  mounted() {
    this.renderChart(this.data, this.options);
  },
};
</script>
